/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import './Styles/ServiceInfo.css'
import Footer from "./Components/Footer";
import { CSSTransition } from 'react-transition-group'
import background from './serviceinfo.png'
import ClientPreview from './Components/ClientPreview'

export default class ServiceInfo extends Component {
    constructor(props) {
        super(props)
        this.handleChangeOptions = this.handleChangeOptions.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.state = {
            chosenOption: 'jasaAnalisis',
            clientPreview: [
                // {
                //     src: '/clients/prima-mandiri.PNG',
                // }
                "CV. Prima Mandiri",
                "PT. Abisha Bumi Persada",
                "PT. Gamindra Mitra Kusuma",
                "PT. Kedap Sayaaq ",
                "PT. Sinergi Membangun Sejahtera",
                "PT. Wira Emas Persada",
                "PT. Parit Mujur Sejahtera",
                "PT. Kahuripan Inti Mineral",
                "PT Bumi Logam Mulia",
                "PT Logam Mulia Kahuripan",
                "PT. Mahadana Arrtu Energie",
                "CV. Empat Sehati",
                "PT. Trimata Coal Perkasa",
                "PT. Pelabuhan Tiga Bersaudara",
                "PT. Persada Agung Sentosa",
                "PT. Sumber Kurnia Buana",
                "PT. Lamindo Inter Multikon",
                "PT. Utami Jaya Mulia",
                "PT. Antang Gunung Meratus",
                "PT. Mega Multi Energi",
                "PT. Mitra Energi Agung",
                "PT. Injatama Mining",
                "PT. Elang Perkasa Mining",
                "PT. Kirana Alam Bersama",
                "PT. Malaki Energi Indonesia"
            ],
            clientIndex: 0,
            isLoaded: false,
            transitionTrigger: true,
            services: [
                `Advised international business contract drafting`,
                `Advised & negotiation permits for the extension of Izin Usaha Pertambangan (IUP), PKP2B, & Kontrak Karya`,
                `Advised IUP OP, IUJP, IUP OPK Angkut Jual, & Pengolahan Pemurnian Minerals & Coal Permit`,
                `Advised construction operations permits to operate coal special port`,
                `Advised blending facility, upgrading coal, and mineral’s smelter construction permits`
            ]
        }
    }

    componentDidMount() {
        const interval = setInterval(() => {
            this.handleIncrementIndex();
        }, 10000);

        return () => clearInterval(interval);
    }

    handleTransition = () => {
        this.setState({
            transitionTrigger: true
        })
    }


    handleIncrementIndex = () => {
        const { clientIndex, clientPreview } = this.state
        if (clientIndex === clientPreview.length - 1) {
            this.setState({
                transitionTrigger: false,
                clientIndex: 0
            }, this.handleTransition)
        } else {
            this.setState({
                transitionTrigger: false,
                clientIndex: clientIndex + 1
            }, this.handleTransition)
        }
    }

    handleChangeOptions(option) {
        this.setState({
            chosenOption: option,
            transitionTrigger: false
        }, this.handleTransition)
    }


    handleSelect(index) {
        this.setState({
            transitionTrigger: false,
            clientIndex: index
        }, this.handleTransition)
    }

    render() {
        const { transitionTrigger, biodata, chosenOption, isLoaded, services, clientPreview, clientIndex } = this.state
        return (
            <>
                <div style={{ backgroundImage: `url(${background})` }} className='serviceinfo-container'>
                    <div className='serviceinfo'>
                        <div className='logo'>
                            <img alt='Minerba Lawfirm' src='/images/logo.png' />
                        </div>
                        <div className='services'>
                            <h1>Our Services</h1>
                            <ul>
                                {
                                    services.map(le => {
                                        return (
                                            <>
                                                <li>{le}</li>
                                            </>
                                        )
                                    })
                                }

                            </ul>
                        </div>

                        <div className='goals'>
                            <h1>Our Goals</h1>
                            <p>Tujuan kami adalah membantu klien dalam mengatasi permasalahan
                                hukum dengan memberikan jasa hukum yang
                                terintegritas dan berpegang teguh pada ketentuan
                                hukum yang berlaku.</p>
                        </div>
                    </div>
                </div>
                <h2 style={{ marginTop: '36px' }}>Our Clients</h2>
                <CSSTransition
                    in={transitionTrigger}
                    classNames="alerts"
                    timeout={1000}
                >
                    <ClientPreview
                        src={clientPreview[clientIndex].src}
                        clientName={clientPreview[clientIndex]}
                        header={clientPreview[clientIndex].header}
                        desc={clientPreview[clientIndex].desc}
                    />
                </CSSTransition>
                <div className='bullets'>
                    {
                        clientPreview.map((image, index) => {
                            return (<a onClick={() => this.handleSelect(index)}>
                                <i style={clientIndex === index ? { color: 'var(--green)' } : {}}
                                    class="fas fa-circle"></i>
                            </a>)
                        })
                    }
                </div>

                <Footer />
            </>
        )
    }
}

