/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Footer from "./Components/Footer";
import './Styles/Home.css'
import { CSSTransition } from 'react-transition-group'
import ImagePreview from './Components/ImagePreview'

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.vision = React.createRef()
        this.home = React.createRef()
        this.handleSelect = this.handleSelect.bind(this)
        this.handleDecrementIndex = this.handleDecrementIndex.bind(this)
        this.handleIncrementIndex = this.handleIncrementIndex.bind(this)
        this.state = {
            imageIndex: 0,
            transitionTrigger: true,
            imageGallery: [],
            legality: [
                `Akta Pendirian Perusahaan dibuat oleh Notaris Fitriawati S.H., M.Kn. Nomor: 14 Pada tanggal 15 Januari 2021`,
                `Akta Perubahan Perusahaan dibuat oleh Notaris Fitriawati S.H., M.Kn. Nomor: 08 Pada tanggal 01 Oktober 2024`,
                `SK Kemenhumham Pendirian: AHU-0000083.AH.01.22 TAHUN 2021 tanggal 18 Januari 2021`,
                `SK Kemenhumham Perubahan: AHU-0000519.AH.01.24 TAHUN 2024 tanggal 11 Oktober 2024`,
                `Nomor Induk Berusaha: 1296000122562 diterbitkan pada tanggal 24 Oktober 2024`
            ],
            mission: [
                {
                    misi: `Memberikan pelayanan konsultasi yang
                terintegritas kepada seluruh klien.`,
                    icon: <i style={{ color: 'var(--yellow)', fontSize:'24px' }} class="far fa-gem"></i>
                },
                {
                    misi: `Memberikan solusi atas permasalahan klien
                    yang terbaik dan menyeluruh.`,
                    icon: <i style={{ color: 'var(--yellow)', fontSize:'24px' }} class="fas fa-hard-hat"></i>
                },
                {
                    misi: ` Memperkuat kepercayaan klien dengan
                    memegang teguh terhadap prinsip kejujuran
                    dan profesionalisme.`,
                    icon: <i style={{ color: 'var(--yellow)', fontSize:'24px' }} class="fas fa-balance-scale"></i>
                }
            ],
            services: [
                `ADVISED INTERNATIONAL BUSINESS CONTRACT DRAFTING`,
                `ADVISED & NEGOTIATION PERMITS FOR THE EXTENSION OF IZIN USAHA
                PERTAMBANGAN (IUP), PKP2B, & KONTRAK KARYA`,
                `ADVISED IUP OP, IUJP, IUP OPK ANGKUT JUAL, & PENGOLAHAN PEMURNIAN
                MINERALS & COAL PERMIT`,
                `ADVISED CONSTRUCTION OPERATIONS PERMITS TO OPERATE COAL SPECIAL
                PORT`,
                `ADVISED BLENDING FACILITY, UPGRADING COAL, AND MINERAL’S SMELTER
                CONSTRUCTION PERMITS
                `
            ]
        }
    }

    componentDidMount() {
        const interval = setInterval(() => {
            this.handleIncrementIndex();
        }, 10000);

        return () => clearInterval(interval);
    }

    handleTransition = () => {
        this.setState({
            transitionTrigger: true
        })
    }

    handleSelect(index) {
        const { imageIndex, imageGallery } = this.state
        this.setState({
            transitionTrigger: false,
            imageIndex: index
        }, this.handleTransition)
    }

    handleIncrementIndex = () => {
        const { imageIndex, imageGallery } = this.state
        if (imageIndex === imageGallery.length - 1) {
            this.setState({
                transitionTrigger: false,
                imageIndex: 0
            }, this.handleTransition)
        } else {
            this.setState({
                transitionTrigger: false,
                imageIndex: imageIndex + 1
            }, this.handleTransition)
        }
    }

    handleDecrementIndex = () => {
        const { imageIndex, imageGallery } = this.state
        if (imageIndex === 0) {
            this.setState({
                transitionTrigger: false,
                imageIndex: imageGallery.length - 1
            }, this.handleTransition)
        } else {
            this.setState({
                transitionTrigger: false,
                imageIndex: imageIndex - 1
            }, this.handleTransition)
        }
    }

    executeScrollVision = () => this.vision.current.scrollIntoView({ behavior: "smooth" })
    executeScrollHome = () => this.home.current.scrollIntoView({ behavior: "smooth" })
    render() {
        const { imageIndex, imageGallery, transitionTrigger, legality, mission, services } = this.state
        return (
            <>
                <div className='home-container'>
                    <div className='desc-container'>
                        <div className='logo'>
                            <img alt='MINERBA' src='/images/logonotulisan.png' />
                        </div>
                        <div className='description'>
                            <h1>About Us</h1>
                            <p style={{textAlign: 'center'}}>MLF Law Firm merupakan kantor
                                hukum yang memiliki pengacara
                                berpengalaman serta berdedikasi
                                tinggi yang mampu memberikan jasa
                                pelayanan hukum, konsultasi dan
                                penanganan masalah yang
                                dialami oleh Klien. MLF Law Firm 
                                mempunyai keahlian dan pengalaman khusus 
                                dalam menangani perkara/permasalahan pertambangan 
                                mineral dan batubara.</p>
                        </div>
                    </div>

                    {/* <div className='background-container'>
                        <div className='background'>
                            <img src='/images/Male-Lawyer-PNG-Clipart.png' alt='Lawyer' />
                        </div>
                    </div> */}
                </div>
                <div className='legality-container'>
                    <div className='legality'>
                        <div className='pic'>
                            <img alt='Legality' src='/images/10491.png' />
                        </div>
                        <div>
                            <h3>Legality</h3>
                            <ul>
                                {
                                    legality.map(le => {
                                        return (
                                            <>
                                                <li>{le}</li>
                                            </>
                                        )
                                    })
                                }

                            </ul>
                        </div>

                    </div>

                </div>

                <div className='bannerbox'>
                    <div className='grid-container'>
                        <div style={{ textAlign: 'center', boxShadow: 'unset' }} className='card-nohover'>
                            <h2>Visi</h2>
                            <p style={{ fontSize: '20px' }}>"Menjadi Salah Satu Kantor Konsultan
                                Hukum Yang Memprioritaskan Keadilan
                                dan Kejujuran dalam Memberikan Layanan
                                Jasa Hukum kepada Klien, serta
                                Memberikan Solusi yang Sesuai Dengan
                                Penegakan Hukum, Norma, dan Moral."</p>

                        </div>
                        <h2 style={{ textAlign: 'center' }}>Misi</h2>
                        <div className='grid'>
                            {
                                mission.map((data, index) => {
                                    return (
                                        <div className='card' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                            <h2>{data.icon}</h2>
                                            <p style={{ textAlign: 'center' }}>{data.misi}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* <div className='service-container'>
                    <div className='desc-container'>
                        <div className='logo'>
                            <img alt='MINERBA' src='/images/logonotulisan.png' />
                        </div>
                        <div className='description'>
                            <h1>Our Goals</h1>
                            <p>Tujuan kami adalah membantu klien dalam mengatasi permasalahan
                                hukum dengan memberikan jasa hukum yang
                                terintegritas dan berpegang teguh pada ketentuan
                                hukum yang berlaku.</p>
                        </div>
                    </div>

                    <div className='services'>
                        <div className='logo'>
                            <img alt='MINERBA' src='/images/logonotulisan.png' />
                        </div>
                        <h1>Our Services</h1>

                        <div className='services'>
                            <ul>
                                {
                                    services.map(le => {
                                        return (
                                            <>
                                                <li>{le}</li>
                                            </>
                                        )
                                    })
                                }

                            </ul>

                        </div>
                    </div>

                </div> */}
                <Footer/>
            </>
        )
    }
}

export default (Home)