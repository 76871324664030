import React from 'react';
import '../Styles/Footer.css'
import background from './footer.jpg'

function Footer() {
  return (
    <>
      <div style={{backgroundImage: `url(${background})`}} className="footer-container">
        <div className="footer-wrap">
          <div style={{ margin: '20px' }} className="footer-cell">
            <div className="information">
              <div className='footer-logo'>
                <img alt='Minerba Lawfirm' src='/images/logo.png' />
              </div>
              <div className='row'>
                {/* <p className='desc'>PT. AGRI NATUR INDONESIA ATAU DISINGKAT PT. AGNATI MERUPAKAN SALAH SATU PERUSAHAAN YANG BERGERAK DI BIDANG JASA KONSULTAN DI BIDANG MINERAL DAN BATU BARA, LINGKUNGAN DAN INDUSTRI.</p> */}
              </div>
            </div>
          </div>
          <div className="footer-cell">
            <div className="information">
              <h3>Contact</h3>
              <div className='row'>
                <i class="fas fa-phone"></i>
                <a href='tel:02150101654'> (021)-50101654</a>
              </div>
              {/* <div className='row'>
                <i class="fas fa-phone"></i>
                <a href='tel:08568906262'>+62 856-8906-262 (Ghania Salsabila)</a>
              </div>
              <div className='row'>
                <i class="fas fa-phone"></i>
                <a href='tel:08568906262'>+62 856-8906-262 (Ryan Febrian Hutabarat)</a>
              </div> */}
              <div className='row'>
                <i class="fas fa-at"></i>
                <a href='mailto:contact.us@,lflawfirm.com'> contact.us@mlflawfirm.com</a>
              </div>
            </div>
          </div>
          <div className="footer-cell">
            <div className="information">
              <h3>Address</h3>
              <div className='row'>
                <i class="fas fa-building"></i>
                <a href='https://maps.app.goo.gl/Lhe26vAeNwLJgG5F6'>Soho Pancoran Nobel Building Suite 1502A.</a>
              </div>
              <div className='row'>
                <i class="fas fa-map-marked"></i>
                <a href='https://maps.app.goo.gl/Lhe26vAeNwLJgG5F6'>Jl. Let . Jend. MT. Haryono Kav 2-3
                  Pancoran, Jakarta
                  12810</a>
              </div>

            </div>
          </div>
          <div className="footer-cell">
            <div className="information">
              <h3>Social Media</h3>
              {/* <div className='row'>
                <i class="fab fa-instagram"></i>
                <a href=''>@minerba_lawfirm</a>
              </div> */}
              <div className='row'>
                <i class="fab fa-linkedin"></i>
                <a href=''>Minerba Lawfirm </a>
              </div>

            </div>
          </div>

        </div>
        <div style={{ justifyContent: 'center' }} className="footer-logo">
          <p style={{ marginLeft: '12px', color: 'grey', fontSize: '12px' }}>Copyright Ⓒ2021 All Rights reserved.</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
